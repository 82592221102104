import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import QRGenerator from "./QRGenerator";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Router>
          <Routes>
            <Route path="/:projectId" element={<QRGenerator />} />
          </Routes>
        </Router>{" "}
      </header>
    </div>
  );
}

export default App;
