import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import axios from "axios";
import { useParams } from "react-router-dom";

const QRGenerator = () => {
  const [qrValue, setQrValue] = useState("");
  const [project, setProject] = useState(null);
  const { projectId } = useParams(); // Récupère le paramètre `projectId` de l'URL

  useEffect(() => {
    const fetchQrValue = async () => {
      try {
        // const response = await axios.get("https://api.poxp.xyz/live/dinovox");
        // const response = await axios.get(
        //   `http://localhost:3000/live/${projectId}`
        // );
        const response = await axios.get(
          `https://api.poxp.xyz/live/${projectId}`
        );
        const data = response.data;
        console.log(data);
        const randomCode =
          data.codes[Math.floor(Math.random() * data.codes.length)];
        console.log(data.project);
        setQrValue(randomCode?.code);
        setProject(data.project);
      } catch (error) {
        console.error("Error fetching QR codes:", error);
      }
    };

    fetchQrValue();

    const interval = setInterval(() => {
      fetchQrValue();
    }, 5000);

    return () => clearInterval(interval);
  }, [projectId]);

  return (
    <div>
      poxp.xyz{" "}
      <span>
        {project?.circulating} / {project?.supply}
      </span>
      <br /> <span>{project?.name}</span>
      <br />
      {qrValue && project?.circulating < project?.supply && (
        <>
          {" "}
          <QRCode
            value={"https://poxp.xyz/claim/" + qrValue}
            style={{ padding: "3px", backgroundColor: "white" }}
          />
          <br />
          <span> {qrValue}</span>
        </>
      )}
    </div>
  );
};

export default QRGenerator;
